import Cookies from 'js-cookie';

var filterBtnAll = document.querySelectorAll('.sf-field-submit input');

if (filterBtnAll) {
  filterBtnAll.forEach(filterBtn => {
    filterBtn.classList.add('btn', 'btn-secondary');
  });
}

var filterDropdownAll = document.querySelectorAll('.searchandfilter .dropdown-toggle');

if (filterDropdownAll) {
  filterDropdownAll.forEach(filterDropdown => {
    filterDropdown.classList.add('dropdown-toggle');

    filterDropdown.addEventListener('click', () => {
      filterDropdown.classList.toggle('show');
    });
  });
}

$(document).on('click', '#reset-filters', () => {
  const url = new URL(window.location.href);
  url.search = '';
  window.location.href = url.href;
});


$(document).on('click', '.edit-research', (e) => {
  e.preventDefault();
  window.location.href = recreateUrlFromCookie(e.target.href);
});

document.addEventListener('DOMContentLoaded', () => { saveFilters(window.location.href); });

// Save user search if set
function saveFilters(url) {
  const urlObj = new URL(url);
  const searchParams = urlObj.search;
  if (searchParams != '')
    Cookies.set('user_custom_search', searchParams);
}

// Recreates a correct URL in order to return to filtered list
function recreateUrlFromCookie(baseUrl) {
  const decodedParams = getUserSearchFiltersStoredInCookies();
  return `${baseUrl}${decodedParams}`;
}

// Fetch user_custom_search from stored cookies
function getUserSearchFiltersStoredInCookies() {
  return Cookies.get('user_custom_search') ?? null;
}
