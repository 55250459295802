window.addEventListener('load', () => {
  // Share on mobile
  let title_to_share = document.querySelector('h1');
  if(document.querySelector('h1')) {
    title_to_share = title_to_share.innerText;
  }else {
    title_to_share = 'Constructeur de maisons';
  }

  const shareData = {
    title: title_to_share,
    url: window.location.href,
  };

  const btn = document.querySelector('#shareButton');

  if(btn) {
    btn.addEventListener('click', () => {
      navigator.share(shareData).then(() => {});
    });
  }
});
