$(function() {
  $(document).on('click','.accordion-faq #more-faq',() => {
    $('#more-faq').addClass('d-none');
    $('.faq-item-more').addClass('show');
    $('#less-faq').removeClass('d-none');
  });

  $(document).on('click','.accordion-faq #less-faq',() => {
    $('#less-faq').addClass('d-none');
    $('.faq-item-more').removeClass('show');
    $('#more-faq').removeClass('d-none');
  });
});
