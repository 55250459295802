const yt_launcher = $('#iframe-launcher');
const yt_iframe = $('#iframe-yt');

if (yt_launcher) {
  $(document).on('click', '#iframe-launcher', (e) => {
    e.preventDefault();
    const srcUrl = yt_iframe.data('src');
    yt_iframe.attr('src', srcUrl);
    yt_launcher.hide();
    yt_iframe.css('display', 'block');
  });
}