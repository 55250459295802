import $ from 'jquery';

$(function () {
  $(document).on('click', '.copy-paste-button', function() {
    let url = $('.copy-btn-url').val();
    let msgSuccess = $('.share-copied-success');

    navigator.clipboard.writeText(url);
    msgSuccess.css('display', 'block');

    // Hide msg after 2s
    setTimeout(function () {
      msgSuccess.css('display', 'none');
    }, 2500);
  });
});
