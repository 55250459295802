/* global crb_app, geojson */
import mapboxgl from 'mapbox-gl';
import { MapboxSearchBox } from '@mapbox/search-js-web';

class MapboxWrapper {
  constructor(mapElement) {
    mapboxgl.accessToken = crb_app.mapbox_token;

    this.mapElement = mapElement;

    this.searchBoxOptions = {
      language: 'fr',
      country: 'FR',
      types: 'city',
    };

    this.map = null;

    this.isLoading = false; // État de chargement des données
    this.offset = 100; // Offset initial pour l'AJAX

    this.initMap();
    this.initSearchBox();
    this.initAlertSearchBox();
  }

  /**
   * Initialise the map
   */
  initMap() {
    let longitude = parseFloat(this.mapElement.getAttribute('data-longitude'));
    let latitude = parseFloat(this.mapElement.getAttribute('data-latitude'));
    let zoom = 10;

    if (typeof longitude === 'undefined' || !longitude || typeof latitude === 'undefined' || !latitude) {
      longitude = 0.0;
      latitude = 44.5200;
      zoom = 7;
    }

    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [longitude, latitude],
      zoom: zoom,
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    let post_type = this.mapElement.getAttribute('data-archive-post-type');

    const url = new URL(window.location.href);

    // don't load more features if filters
    if (!url.search) {
      this.map.on('moveend', () => {
        if (post_type === 'land' || post_type === 'land_house') {
          this.loadMoreFeatures(this.offset, post_type);
        }
      });
    }

    this.map.on('load', async () => {

      this.setupIntersectionObserver();

      const boutonzoomin = document.querySelector('.mapboxgl-ctrl-zoom-in');
      const boutonzoomout = document.querySelector('.mapboxgl-ctrl-zoom-out');
      const zoominsvg = '<img class="picto-zoom" src="/wp-content/themes/maisons-cote-atlantique/assets/images/svg/plus.svg">';
      const zoomoutsvg = '<img class="picto-zoom" src="/wp-content/themes/maisons-cote-atlantique/assets/images/svg/less.svg">';

      boutonzoomin.innerHTML = zoominsvg;
      boutonzoomout.innerHTML = zoomoutsvg;
      this.map.addSource('features', {
        type: 'geojson',
        data: geojson,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      const featureCount = geojson.features.length;
      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'features',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#e53561',
            4,
            '#e53561',
            5,
            '#e53561',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            15,
            4,
            25,
            5,
            40,
          ],
        },
      });

      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'features',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
          'text-color': '#fff',
        },
        paint: {
          "text-color": "#ffffff",
        },
      });

      if (featureCount > 1) {
        // Case when we're on main map
        this.map.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'features',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#fff',
            'circle-radius': 12,
            'circle-stroke-width': 4,
            'circle-stroke-color': '#e53561',
          },
        });
      } else if (featureCount === 1) {
        // Case when we're on a land or land + house detail page map
        this.map.addLayer({
          id: 'radius',
          type: 'circle',
          source: 'features',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#e1b5b4',
            'circle-radius': {
              stops: [
                [0, 0],
                [20, metersToPixelsAtMaxZoom(3000, geojson.features[0].geometry.coordinates[0])],
              ],
              base: 1.75,
            },
            'circle-stroke-width': 2,
            'circle-stroke-color': '#e53561',
            'circle-opacity': 0.5,
          },
        });
      }


      // inspect a cluster on click
      this.map.on('click', 'clusters', (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        });
        const clusterId = features[0].properties.cluster_id;
        this.map.getSource('features').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            this.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          }
        );
      });
      this.map.on('click', 'unclustered-point', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const image = e.features[0].properties.image;
        const name = e.features[0].properties.name;
        const price = e.features[0].properties.price;
        const surface = e.features[0].properties.surface;
        const url = e.features[0].properties.url;

        this.map.flyTo({
          center: e.features[0].geometry.coordinates,
          zoom: 22,
        });

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `
            <div class="popup-container">
              <div class="popup-content">
                <div class="hide"></div>
                ${image ? `<div class="popup-content-image">${image}</div>` : ''}
                ${name ? `<div class="popup-content-name">${name}</div>` : ''}
                ${price ? `<div class="popup-content-price">${price} €</div>` : ''}
                ${surface ? `
                  <div class="popup-content-surface">
                    <svg class="svg-surface-land-dims">
                      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/maisons-cote-atlantique/dist/images/sprite.symbol.svg#surface-land"></use>
                    </svg>
                    ${surface} m²
                  </div>` : ''}
                ${url ? `<a class="popup-content-link" href="${url}">
                    <svg class="svg-arrow-right-dims">
                      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/maisons-cote-atlantique/dist/images/sprite.symbol.svg#arrow-right"></use>
                    </svg>
                  </a>` : ''}
              </div>
              <div class="popup-content-pin"></div>
            </div>`
          ).addTo(this.map);

      });

      this.map.on('mouseenter', 'clusters', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });

      this.map.on('mouseleave', 'clusters', () => {
        this.map.getCanvas().style.cursor = '';
      });

    });
  }

  setupIntersectionObserver() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.map.resize();
          observer.unobserve(entry.target); // Ensure it only triggers once
        }
      });
    }, options);

    const mapElement = document.getElementById('map');
    if (mapElement) {
      observer.observe(mapElement);
    }
  }

  /**
   * Initialize the search box
   */
  initSearchBox() {
    const searchBoxElement = new MapboxSearchBox();
    searchBoxElement.accessToken = crb_app.mapbox_token;
    searchBoxElement.options = this.searchBoxOptions;
    searchBoxElement.placeholder = 'Localisation';

    const searchBoxContainer = document.querySelector('#search-box');

    if (!searchBoxContainer) {
      return;
    }

    searchBoxContainer.appendChild(searchBoxElement);

    // Init search value
    searchBoxElement.value = document.querySelector('#city').value;

    searchBoxElement.addEventListener('retrieve', (e) => {
      const location = e.detail;

      if (location.features && location.features.length) {
        const coords = location.features[0]['geometry']['coordinates'];
        const lat = document.querySelector('#latitude');
        const long = document.querySelector('#longitude');
        const city = document.querySelector('#city');
        city.value = location.features[0]['properties']['name'];

        if (lat && long) {
          long.value = coords[0];
          lat.value = coords[1];
        }
      }
    });
  }

  /**
   * Initialize the alert search box
   */
  initAlertSearchBox() {
    const searchBoxElement = new MapboxSearchBox();
    searchBoxElement.accessToken = crb_app.mapbox_token;
    searchBoxElement.options = this.searchBoxOptions;
    searchBoxElement.placeholder = 'Localisation';

    const searchBoxContainer = document.querySelector('#alert-search-box');

    if (!searchBoxContainer) {
      return;
    }

    searchBoxContainer.appendChild(searchBoxElement);

    // Init search value
    searchBoxElement.value = document.querySelector('#alert-city').value;

    searchBoxElement.addEventListener('retrieve', (e) => {
      const location = e.detail;

      if (location.features && location.features.length) {
        const city = document.querySelector('#alert-city');
        const latitude = document.querySelector('#alert-latitude');
        const longitude = document.querySelector('#alert-longitude');
        city.value = location.features[0]['properties']['name'];
        latitude.value = location.features[0]['properties']['coordinates']['latitude'];
        longitude.value = location.features[0]['properties']['coordinates']['longitude'];

      }
    });
  }

  /**
 * Load more features dynamically via AJAX.
 */
  loadMoreFeatures(offset = 0, post_type) {
    if (this.isLoading) return;
    this.isLoading = true;

    $.ajax({
      type: 'POST',
      url: crb_app.ajax_url,
      data: {
        action: 'load_more_announces',
        post_type: post_type,
        offset: offset,
      },
      dataType: 'json',
      success: (newFeatures) => {
        if (Array.isArray(newFeatures) && newFeatures.length > 0) {
          // Ajouter les nouvelles données aux features existantes
          geojson.features = geojson.features.concat(newFeatures);
          this.map.getSource('features').setData(geojson);
          this.offset += newFeatures.length;
        }
      },
      error: (xhr, status, error) => {
        // eslint-disable-next-line no-console
        console.error('Erreur lors du chargement des données supplémentaires :', error);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }


}

// Converts given meter radius into a proper scaling radius
const metersToPixelsAtMaxZoom = (meters, latitude) => {
  return meters / 0.075 / Math.cos(latitude * Math.PI / 180);
};

document.addEventListener('DOMContentLoaded', function () {

  const map = document.querySelector('#map');
  if (!map) {
    return;
  }

  new MapboxWrapper(map);
});

const searchBoxContainer = document.querySelector('#alert-search-box-autocomplete');
if (searchBoxContainer) {

  const searchBoxElement = new MapboxSearchBox();
  searchBoxElement.accessToken = crb_app.mapbox_token;
  searchBoxElement.options = {
    language: 'fr',
    country: 'FR',
    types: 'city',
  };
  searchBoxElement.theme = {
    variables: {
      border: '1px solid #d2d2d2',
      borderRadius: '10px',
      boxShadow: 'none',
      colorBackground: '#fcf7f2',
    },
  };

  searchBoxElement.placeholder = 'Localisation';

  searchBoxContainer.appendChild(searchBoxElement);

  // Init search value
  searchBoxElement.value = document.querySelector('#alert-city-autocomplete').value;

  searchBoxElement.addEventListener('retrieve', (e) => {
    const location = e.detail;

    if (location.features && location.features.length) {
      const city = document.querySelector('#alert-city-autocomplete');
      city.value = location.features[0]['properties']['name'];

    }
  });
}