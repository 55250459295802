/* global crb_app, geojson */
import mapboxgl from 'mapbox-gl';
import { MapboxSearchBox } from '@mapbox/search-js-web';

class MapboxWrapper {
  constructor() {
    mapboxgl.accessToken = crb_app.mapbox_token;

    this.searchBoxOptions = {
      language: 'fr',
      country: 'FR',
      types: 'city',
    };

    this.map = null;

    this.initMap();
    this.initSearchBox();
  }

  /**
   * Initialise the map
   */
  initMap() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [8.16263, 49.17416],
      zoom: 3.90,
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('load', async () => {
      this.setupIntersectionObserver();

      const boutonzoomin = document.querySelector('.mapboxgl-ctrl-zoom-in');
      const boutonzoomout = document.querySelector('.mapboxgl-ctrl-zoom-out');
      const zoominsvg = '<svg style="color: white;" class="svg-plus-dims"> <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/maisons-cote-atlantique/dist/images/sprite.symbol.svg#plus"></use></svg>';
      const zoomoutsvg ='<svg style="color: white;" class="svg-less-dims"> <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/maisons-cote-atlantique/dist/images/sprite.symbol.svg#less"></use></svg>';

      boutonzoomin.innerHTML = zoominsvg;
      boutonzoomout.innerHTML = zoomoutsvg;

      this.map.addSource('features', {
        type: 'geojson',
        data: geojson,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      const featureCount = geojson.features.length;

      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'features',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#e53561',
            4,
            '#e53561',
            5,
            '#e53561',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            15,
            4,
            25,
            5,
            40,
          ],
        },
      });

      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'features',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
          'text-color': '#fff',
        },
        paint: {
          "text-color": "#ffffff",
        },
      });

      if(featureCount > 1) {
        // Case when we're on main map
        this.map.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'features',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#fff',
            'circle-radius': 12,
            'circle-stroke-width': 4,
            'circle-stroke-color': '#e53561',
          },
        });
      } else {
        // Case when we're on a land or land + house detail page map
        this.map.addLayer({
          id: 'radius',
          type: 'circle',
          source: 'features',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#e1b5b4',
            'circle-radius': {
              stops: [
                [0, 0],
                [20, metersToPixelsAtMaxZoom(5000, geojson.features[0].geometry.coordinates[0])],
              ],
              base: 1.75,
            },
            'circle-stroke-width': 2,
            'circle-stroke-color': '#e53561',
            'circle-opacity': 0.5,
          },
        });
      }

      // inspect a cluster on click
      this.map.on('click', 'clusters', (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        });
        const clusterId = features[0].properties.cluster_id;
        this.map.getSource('features').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            this.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          }
        );
      });

      this.map.on('click', 'unclustered-point', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const image = e.features[0].properties.image;
        const name = e.features[0].properties.name;
        const price = e.features[0].properties.price;
        const surface = e.features[0].properties.surface;
        const url = e.features[0].properties.url;

        this.map.flyTo({
          center: e.features[0].geometry.coordinates,
          zoom: 18,
        });

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `
            <div class="popup-container">
              <div class="popup-content">
                <div class="hide"></div>
                ${image ? `<div class="popup-content-image">${image}</div>` : ''}
                ${name ? `<div class="popup-content-name">${name}</div>` : ''}
                ${price ? `<div class="popup-content-price">${price} €</div>` : ''}
                ${surface ? `
                  <div class="popup-content-surface">
                    <svg class="svg-surface-land-dims">
                      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/maisons-cote-atlantique/dist/images/sprite.symbol.svg#surface-land"></use>
                    </svg>
                    ${surface} m²
                  </div>` : ''}
                ${url ? `<a class="popup-content-link" href="${url}">
                    <svg class="svg-arrow-right-dims">
                      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/maisons-cote-atlantique/dist/images/sprite.symbol.svg#arrow-right"></use>
                    </svg>
                  </a>` : ''}
              </div>
              <div class="popup-content-pin"></div>
            </div>`
          ).addTo(this.map);

      });

      this.map.on('mouseenter', 'clusters', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });

      this.map.on('mouseleave', 'clusters', () => {
        this.map.getCanvas().style.cursor = '';
      });

    });
  }

  setupIntersectionObserver() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.map.resize();
          observer.unobserve(entry.target); // Ensure it only triggers once
        }
      });
    }, options);

    const mapElement = document.getElementById('map');
    if (mapElement) {
      observer.observe(mapElement);
    }
  }

  /**
   * Initialize the search box
   */
  initSearchBox() {
    const searchBoxElement = new MapboxSearchBox();
    searchBoxElement.accessToken = crb_app.mapbox_token;
    searchBoxElement.options = this.searchBoxOptions;

    const searchBoxContainer = document.querySelector('#search-box');

    if (!searchBoxContainer) {
      return;
    }

    searchBoxContainer.appendChild(searchBoxElement);

    searchBoxElement.addEventListener('retrieve', (e) => {
      const location = e.detail;

      if (location.features && location.features.length) {
        const coords = location.features[0]['geometry']['coordinates'];
        const lat = document.querySelector('#latitude');
        const long = document.querySelector('#longitude');

        if (lat && long) {
          lat.value = coords[0];
          long.value = coords[1];
        }
      }
    });
  }
}

// Converts given meter radius into a proper scaling radius
const metersToPixelsAtMaxZoom = (meters, latitude) => {
  return meters / 0.075 / Math.cos(latitude * Math.PI / 180);
};

document.addEventListener('DOMContentLoaded', function() {
  const map = document.querySelector('#map');

  if (!map) {
    return;
  }
  new MapboxWrapper();
});


