class Calculator {

  constructor() {
    this.calculator = null;
  }

  initResult(monthlyPayment, contribution, loanCapacity, duration, taxRate, creditValue) {
    this.calculator = `
    <div class="calculator-results">
      <p>Avec une mensualité de <span class="calculator-informations"> ${this.formatValue(monthlyPayment)} €</span>, et un apport de <span class="calculator-informations"> ${this.formatValue(contribution)} € </span></p>
      <p>Vous pourrez emprunter <span class="calculator-informations"> ${this.formatValue(loanCapacity)} € </span></p>
      <p>Sur une durée de <span class="calculator-informations"> ${duration} </span> ans à un taux de <span class="calculator-informations"> ${taxRate}%</span></p>
      <p class="calculator-informations-important mb-3">Le coût du crédit est de <span class="calculator-informations"> ${this.formatValue(creditValue)} €</span></p>
    </div>
    <div>
      <h3 class="calculator-title-form">
        Vous voulez acheter ce bien ? <br>
        Contactez-nous pour obtenir votre étude personnalisée :
      </h3>
    </div>
    `;
  }

  calculate(monthlyPayment, contribution, duration, taxRate) {
    const monthlyInterestRate = parseFloat(taxRate) / 100 / 12;
    const monthlyPaymentNum = parseFloat(monthlyPayment);
    const contributionNum = parseFloat(contribution);
    const durationNum = parseInt(duration, 10);

    let loanCapacity;

    if (monthlyInterestRate === 0) {
        loanCapacity = monthlyPaymentNum * durationNum;
    } else {
        loanCapacity = (monthlyPaymentNum * (1 - Math.pow(1 + monthlyInterestRate, -durationNum))) / monthlyInterestRate;
    }

    loanCapacity = Math.round(loanCapacity + contributionNum);

    const totalPayment = monthlyPaymentNum * durationNum;
    const creditValue =  Math.round(Math.max(totalPayment - (loanCapacity - contributionNum), 0));

    this.initResult(monthlyPayment, contribution, loanCapacity, duration, taxRate, creditValue);
    return this.calculator;
  }

  formatValue(amount) {
    const absAmount = Math.abs(Math.round(amount));
    return absAmount >= 1000 ? absAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : absAmount.toString();
  }
}

$(document).on('click', '#calculate', (e) => {
  e.preventDefault();
  const monthlyPayment = $('#monthly-payment').val();
  const contribution = $('#contribution').val();
  const duration = $('#duration').val();
  const taxRate = $('#tax-rate').val();

  $('#calculator-first-step').hide();

  const calculator = new Calculator();
  const output = calculator.calculate(monthlyPayment, contribution, duration, taxRate);
  $('#calculator-second-step').prepend(output);
  $('#calculator-second-step').show();
});
