$('.rdv-link .nav-link').attr('data-bs-toggle', 'modal');
$('.rdv-link .nav-link').attr('data-bs-target', '#modal-rdv');

// Display a popup when unsubscribing
const $unsubscribeModal = $('#unsubscribe-modal');
if ($unsubscribeModal.length) {

  $unsubscribeModal.css('display', 'block')
    .attr('aria-hidden', 'false')
    .addClass('show');

  const closeModal = (event) => {
    if ($(event.target).is($unsubscribeModal) || event.key === 'Escape') {
      $unsubscribeModal.css('display', 'none')
        .attr('aria-hidden', 'true')
        .removeClass('show');
      $(document).off('keydown', closeModal);
      $unsubscribeModal.off('click', closeModal);
    }
  };

  $(document).on('keydown', closeModal);
  $unsubscribeModal.on('click', closeModal);
}