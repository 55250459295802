// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tab';

import './modules/contact-decal';
import './modules/faq-more';
import './modules/fancybox';
import './modules/filters';
import './modules/hamburger';
import './modules/mobile-menu';
import './modules/modals';
import './modules/share-desktop';
import './modules/share-mobile';
import './modules/sliders';
import './modules/sticky-header';
import './modules/toggle-map';
import './modules/mapbox';
import './modules/youtube-iframe';
import './modules/calculator';
